import Badge from "@/admin/experiences/badges/Badge"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { EditorPathwayAttachBlockChildProductFragment$key } from "@components/editor/plugins/attach-block/types/pathway/__generated__/EditorPathwayAttachBlockChildProductFragment.graphql"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { Collapse } from "@material-ui/core"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

export default function EditorPathwayAttachBlockChildProduct({
  productKey,
}: {
  productKey: EditorPathwayAttachBlockChildProductFragment$key
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const product = useFragment<EditorPathwayAttachBlockChildProductFragment$key>(
    graphql`
      fragment EditorPathwayAttachBlockChildProductFragment on Product {
        id
        name
        description
        startsAt
        badge {
          ...BadgeFragment
        }
        curriculumPreview {
          totalCount
          edges {
            node {
              id
              content {
                id
                name
                description
              }
            }
          }
        }
      }
    `,
    productKey
  )
  const classes = useStyles()
  const modules = Relay.connectionToArray(product.curriculumPreview)
  const showCollapse = Boolean(product.description || modules.length)

  return (
    <DiscoSection groovyDepths={"insideCard"} margin={0.5} padding={0}>
      <DiscoSection className={classes.header} padding={2}>
        <Badge badgeKey={product.badge!} size={48} />
        <div>
          <DiscoText variant={"body-md-600"}>{product.name}</DiscoText>
          {!product.startsAt && (
            <DiscoText color={"text.secondary"} variant={"body-sm"}>
              {"No fixed duration"}
            </DiscoText>
          )}
        </div>
        {showCollapse && (
          <DiscoIconButton
            onClick={() => setIsExpanded((prev) => !prev)}
            size={"small"}
            className={classes.collapseButton}
          >
            <DiscoIcon
              icon={"chevron"}
              rotate={isExpanded ? undefined : "180"}
              width={20}
              height={20}
            />
          </DiscoIconButton>
        )}
      </DiscoSection>
      <Collapse in={isExpanded}>
        <DiscoDivider style={{ padding: 0, marginTop: 0, marginBottom: 0 }} />
        <DiscoSection padding={2}>
          <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1.5}>
            {product.description}
          </DiscoText>
          {Boolean(modules.length) &&
            modules.map((module) => (
              <div key={module.id}>
                <DiscoText
                  variant={"body-sm-600"}
                  color={"text.primary"}
                  marginBottom={1}
                >
                  {module.content.name}
                </DiscoText>
                <DiscoText
                  variant={"body-sm"}
                  color={"text.secondary"}
                  marginBottom={1.5}
                >
                  {module.content.description}
                </DiscoText>
              </div>
            ))}
        </DiscoSection>
      </Collapse>
    </DiscoSection>
  )
}
const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  collapseButton: {
    marginLeft: "auto",
  },
}))
