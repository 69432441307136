import CreateAutomationModal from "@/admin/automation/button/CreateAutomationModal"
import ExperienceTemplatesModal from "@/admin/experiences/create/ExperienceTemplatesModal"
import ContentTemplatesModal from "@/content/templates/ContentTemplatesModal"
import { CURRICULUM_SYSTEM_TASKS } from "@/content/util/contentTemplates"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { extractFeedId } from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CreateInvitationModal from "@/invitation/CreateInvitationModal"
import CreateEventModal from "@/organization/occurrence/create-form/CreateEventModal"
import { CreatePostParams } from "@/post/add/CreatePostButton"
import CreatePostModal from "@/post/add/CreatePostModal"
import NewChannelMessageModal from "@components/chat/channel/NewChannelMessageModal"
import NewDirectMessageModal from "@components/chat/new-direct-message/NewDirectMessageModal"
import GlobalAddDropdownButton from "@components/global-add/GlobalAddDropdownButton"
import GlobalAddDropdownItem, {
  GLOBAL_ADD_DROPDOWN_ITEM_KIND,
} from "@components/global-add/GlobalAddDropdownItem"
import { useViewerCanCreateEvents } from "@components/global-add/useViewerCanCreateEvents"
import { GlobalAddDropdownQuery } from "@components/global-add/__generated__/GlobalAddDropdownQuery.graphql"
import { DiscoDivider, DiscoDropdown, DiscoText } from "@disco-ui"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import classNames from "classnames"
import React, { useRef, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

function GlobalAddDropdown() {
  const classes = useStyles()
  const [closeGlobalAddDropdown, setCloseGlobalAddDropdown] = useState(false)
  const [openGlobalModal, setOpenGlobalModal] =
    useState<GLOBAL_ADD_DROPDOWN_ITEM_KIND | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const history = useHistory()
  const viewerMembershipId = activeOrganization?.viewerMembership?.id

  const { membership } = useLazyLoadQuery<GlobalAddDropdownQuery>(
    graphql`
      query GlobalAddDropdownQuery($membershipId: ID!) {
        membership: node(id: $membershipId) {
          ... on OrganizationMembership {
            id
            ...useViewerCanCreateEventsFragment
          }
        }
      }
    `,
    {
      membershipId: viewerMembershipId || "",
    }
  )

  const canViewerCreateEvents = useViewerCanCreateEvents(membership)

  const permissions = useActiveProductOrOrganizationPermissions()
  const [, setParams] = useQueryParamState<CreatePostParams>()
  const feedId = extractFeedId(location.pathname)
  const showDMs = activeOrganization?.isDmEnabled
  const showChannels = activeOrganization?.isChannelsEnabled
  const showContent = activeOrganization?.viewerPermissions.has("content.manage")
  const showProducts =
    permissions.has("products.create") && activeOrganization?.isProductsEnabled
  const showEvents = activeOrganization?.isEventsEnabled && canViewerCreateEvents
  const showAutomations = activeOrganization?.viewerPermissions.has("automations.manage")
  const showInvitation = permissions.has("invitations.manage")
  const TESTID = "GlobalAddDropdown"
  const isCourse = activeProduct?.type === "course"
  const isPathway = activeProduct?.type === "pathway"

  function renderGlobalModal() {
    switch (openGlobalModal) {
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.direct_message:
        return (
          <NewDirectMessageModal
            isOpen={openGlobalModal === GLOBAL_ADD_DROPDOWN_ITEM_KIND.direct_message}
            onClose={onModalClose}
          />
        )
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.channel_message:
        return (
          <NewChannelMessageModal
            isOpen={openGlobalModal === GLOBAL_ADD_DROPDOWN_ITEM_KIND.channel_message}
            onClose={onModalClose}
          />
        )
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.post:
        return (
          <CreatePostModal
            feedId={feedId ?? undefined}
            onClose={() => {
              onModalClose()
              setParams({ createPost: undefined })
            }}
          />
        )
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.content:
        return (
          <ContentTemplatesModal
            templatesToExclude={CURRICULUM_SYSTEM_TASKS}
            onClose={onModalClose}
          />
        )
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.event:
        return <CreateEventModal onClose={onModalClose} />
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.product:
        return <ExperienceTemplatesModal onClose={onModalClose} />
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.automation:
        return <CreateAutomationModal onClose={onModalClose} />
      case GLOBAL_ADD_DROPDOWN_ITEM_KIND.invite_member:
        return (
          <CreateInvitationModal
            onClose={onModalClose}
            isOpen={openGlobalModal === GLOBAL_ADD_DROPDOWN_ITEM_KIND.invite_member}
            productId={isCourse || isPathway ? activeProduct.id : null}
            onSubmit={onModalClose}
          />
        )
      default:
        return null
    }
  }
  return (
    <div ref={ref} className={classNames(classes.dropdownContainer)}>
      <DiscoDropdown
        testid={`${TESTID}.menu`}
        onClose={handleCloseGlobalAddDropdown}
        menuButton={({ onClick }) => (
          <GlobalAddDropdownButton
            close={closeGlobalAddDropdown}
            onClick={onClick}
            data-testid={`${TESTID}.button`}
          />
        )}
        menuClasses={{ paper: classes.dropdownStyles }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={classes.item}>
          <DiscoText variant={"body-sm-700"} className={classes.addText}>
            {"Add"}
          </DiscoText>
        </div>
        {showDMs && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.direct_message}
            onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.direct_message)}
          />
        )}
        {showChannels && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.channel_message}
            onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.channel_message)}
          />
        )}

        <GlobalAddDropdownItem
          testid={TESTID}
          kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.post}
          onClick={() => {
            handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.post)
            setParams({ createPost: "true" })
          }}
        />

        {(showContent || showEvents || showProducts || showAutomations) && (
          <DiscoDivider marginTop={0.5} marginBottom={0.5} />
        )}
        {showContent && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.content}
            onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.content)}
          />
        )}
        {showEvents && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.event}
            onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.event)}
          />
        )}
        {showProducts && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.product}
            onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.product)}
          />
        )}
        {showAutomations && (
          <GlobalAddDropdownItem
            testid={TESTID}
            kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.automation}
            onClick={() => {
              history.push(ROUTE_NAMES.ADMIN.AUTOMATIONS)
              handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.automation)
            }}
          />
        )}
        {showInvitation && (
          <div>
            <DiscoDivider marginTop={0.5} marginBottom={0.5} />
            <GlobalAddDropdownItem
              testid={TESTID}
              kind={GLOBAL_ADD_DROPDOWN_ITEM_KIND.invite_member}
              onClick={() => handleSelect(GLOBAL_ADD_DROPDOWN_ITEM_KIND.invite_member)}
            />
          </div>
        )}
      </DiscoDropdown>

      {renderGlobalModal()}
    </div>
  )

  function handleCloseGlobalAddDropdown() {
    setCloseGlobalAddDropdown(true)
    setTimeout(() => {
      setCloseGlobalAddDropdown(false)
    }, 50)
  }

  function handleSelect(selection: GLOBAL_ADD_DROPDOWN_ITEM_KIND) {
    handleCloseGlobalAddDropdown()
    setOpenGlobalModal(selection)
  }

  function onModalClose() {
    setOpenGlobalModal(null)
  }
}

const useStyles = makeUseStyles((theme) => ({
  dropdownContainer: {
    position: "relative",
  },
  item: {
    height: theme.spacing(5),
    margin: `${theme.spacing(1)} !important`,
    display: "flex",
    alignItems: "center",
  },
  addText: {
    padding: theme.spacing(2),
    color: undefined,
  },
  dropdownStyles: {
    padding: `${theme.spacing(0.5)}px !important`,
    width: "220px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.big,
    iconButton: {
      color: theme.palette.groovy.grey[300],
      "&:hover": {
        color: theme.palette.groovy.grey[500],
      },
    },
  },
}))

export default React.memo(GlobalAddDropdown)
