import { ApiErrorShape } from "@/core/network-manager/networkModels"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import FormSubmitButton from "@components/form/FormSubmitButton"
import { isMoreContent } from "@components/scroll-shadow/showShadowUtils"
import { DiscoAlert, useModalContext } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { ERROR_TYPES } from "@utils/error/errorConstants"
import { generateErrorMessage } from "@utils/error/errorUtils"
import { ArrayToUnion, TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { v4 } from "uuid"

export interface FormProps extends Omit<StyleProps, "isInModal">, TestIDProps {
  id?: string
  onSubmit?: () => void
  children?: React.ReactNode
  errorInfo?: ApiErrorShape | null
  knownErrorKeys?: string[] | null
  onChange?: (event: React.SyntheticEvent) => void
  customClassName?: string
  isSubmitAllowed?: boolean
  skipErrorTypes?: Array<ArrayToUnion<typeof ERROR_TYPES>>
  autoComplete?: string
  buttons?: React.ReactNode
  classes?: Partial<ClassNameMap<"formFieldsContainer" | "buttonsRoot">>
  formError?: string
  buttonsContainerRef?: HTMLDivElement | null
}

function Form(props: FormProps) {
  const {
    id,
    children,
    testid,
    onSubmit,
    customClassName,
    errorInfo,
    knownErrorKeys,
    isSubmitAllowed = true,
    skipErrorTypes,
    autoComplete = "off",
    hasStickyButtons = false,
    buttons,
    classes: customClasses,
    height,
    formError,
    buttonsContainerRef,
  } = props
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const [isPristine, setPristine] = useState(true)

  const formErrorMessage =
    formError ??
    generateErrorMessage(errorInfo, {
      knownErrorKeys,
      skipTypes: skipErrorTypes,
    })

  const modal = useModalContext()
  const buttonsRef = buttonsContainerRef || modal?.buttonsRef
  const classes = useStyles({ hasStickyButtons, height, isInModal: Boolean(modal) })
  const [isFormScrolled, setIsFormScrolled] = useState(
    scrollContainerRef.current?.scrollTop === 0
  )
  const [shouldShowFooterShadow, setShouldShowFooterShadow] = useState<boolean>(false)
  useEffect(() => {
    // on mount, evaluate if we should show footer shadow, and re-eval on resize
    const handleFooterShadow = () =>
      setShouldShowFooterShadow(isMoreContent(scrollContainerRef))
    window.addEventListener("resize", handleFooterShadow)
    return () => window.removeEventListener("resize", handleFooterShadow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formElementId = useUniqueFormElementId()
  const formId = id ?? formElementId

  return (
    <FormContext.Provider value={{ id: formId }}>
      <form
        id={formId}
        className={classNames(
          "form",
          { [classes.form]: hasStickyButtons },
          customClassName
        )}
        data-testid={testid}
        onChange={handleFormChange}
        onSubmit={handleSubmit}
        autoComplete={autoComplete}
      >
        {Boolean(formErrorMessage) && (
          <DiscoAlert marginBottom={3} severity={"error"} message={formError} />
        )}
        <div
          className={classNames({
            [classes.showHeaderShadow]: hasStickyButtons && isFormScrolled,
          })}
        />
        <div
          ref={scrollContainerRef}
          className={classNames(
            {
              [classes.formFieldsContainer]: hasStickyButtons,
            },
            customClasses?.formFieldsContainer
          )}
          onScroll={onScroll}
        >
          {children}
        </div>

        {buttons &&
          // Inside modal, render buttons into modal
          (buttonsRef ? (
            ReactDOM.createPortal(buttons, buttonsRef)
          ) : (
            <div
              className={classNames(
                classes.buttonsContainer,
                props.classes?.buttonsRoot,
                {
                  [classes.showFooterShadow]: hasStickyButtons && shouldShowFooterShadow,
                }
              )}
            >
              {buttons}
            </div>
          ))}
      </form>
    </FormContext.Provider>
  )

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.stopPropagation()
    event.preventDefault()

    if (isSubmitAllowed && onSubmit) {
      onSubmit()
    }
  }

  function handleFormChange(event: React.SyntheticEvent) {
    const { onChange } = props

    if (isPristine) {
      setPristine(false)
    }

    if (onChange) {
      onChange(event)
    }
  }

  function onScroll() {
    if (scrollContainerRef.current) {
      setIsFormScrolled(Boolean(scrollContainerRef.current.scrollTop))
      setShouldShowFooterShadow(isMoreContent(scrollContainerRef))
    }
  }
}

const FormContext = React.createContext({ id: "" })
function useFormContext() {
  return React.useContext(FormContext)
}
function useUniqueFormElementId(prefix = "", suffix = "") {
  return useRef(`${prefix}${v4()}${suffix}`).current
}

interface StyleProps {
  hasStickyButtons?: boolean
  height?: React.CSSProperties["height"]
  isInModal: boolean
}

const useStyles = makeUseStyles((theme) => ({
  form: ({ height }: StyleProps) => ({
    position: "relative",
    overflow: "hidden",
    // form or parent element of form must have set height for scroll shadow/stickyButton behaviour
    height,
  }),
  showHeaderShadow: {
    boxShadow: "0px 1px 1px rgb(46 64 88 / 3%), 0px 5px 24px rgb(46 64 88 / 5%)",
    width: "100%",
    height: "24px",
    top: "-24px",
    position: "absolute",
    zIndex: 1,
    pointerEvents: "none",
  },
  formFieldsContainer: ({ isInModal }: StyleProps) => ({
    // 69px or 93px for buttonContainer height + padding if `hasStickyButtons`
    height: `calc(100% - ${isInModal ? "69px" : "93px"})`,
    overflow: "hidden auto",
  }),
  showFooterShadow: {
    boxShadow: "0px -1px 1px rgb(46 64 88 / 3%), 0px -5px 24px rgb(46 64 88 / 5%)",
  },
  buttonsContainer: (props: StyleProps) => ({
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(4),
    ...styleIf(props.hasStickyButtons, {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
      marginTop: 0,
      padding: props.isInModal ? theme.spacing(3, 0, 0) : theme.spacing(3),
    }),
  }),
}))

Form.SubmitButton = FormSubmitButton
Form.useUniqueFormElementId = useUniqueFormElementId
Form.useFormContext = useFormContext

export default Form
