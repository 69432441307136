import { AdminContentListToolbarState } from "@/admin/content-library/filters/AdminContentListToolbar"
import ContentUtils from "@/content/util/contentUtils"
import DropdownIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import {
  EventsFilterOption,
  EVENTS_FILTER_OPTIONS,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockContentUsageSearchBody from "@components/editor/plugins/attach-block/types/content-usage/AttachBlockContentUsageSearchBody"
import AttachBlockEventSearchBody from "@components/editor/plugins/attach-block/types/event/AttachBlockEventSearchBody"
import AttachBlockProductSearchBody from "@components/editor/plugins/attach-block/types/product/AttachBlockProductSearchBody"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
  SelectOption,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import DiscoTableSearchInput from "@disco-ui/table/header/search/DiscoTableSearchInput"
import { capitalize, Popover, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useRef, useState } from "react"

type AdminContentListLabelOption = SelectOption<GlobalID | null>

export interface AttachBlockToolbarState extends AdminContentListToolbarState {
  eventFilter: EventsFilterOption
}
interface AttachBlockSelectModalProps extends TestIDProps {
  isOpen: boolean
  onClose: VoidFunction
  anchorElem: HTMLElement | null
  attachBlockEntity: AttachBlockEntity
  getAttachBlockEntityId?: (
    attachBlockEntity: AttachBlockEntity,
    entityId: string
  ) => void
  inline: boolean
}

function AttachBlockSelectModal({
  isOpen,
  onClose,
  anchorElem,
  attachBlockEntity,
  getAttachBlockEntityId,
  inline,
}: AttachBlockSelectModalProps) {
  const popoverAnchorRef = useRef<HTMLDivElement | null>(null)
  const [toolbarState, setToolbarState] = useState<AttachBlockToolbarState>({
    search: "",
    contentLabelId: null,
    selectedProduct: null,
    selectedCollection: null,
    contentUsageCount: null,
    sort: {
      id: "none",
      order: { field: "created_at" },
      title: "Sort",
    },
    direction: "DESC",
    eventFilter: "upcoming",
  })

  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const ID = `attach-block-${attachBlockEntity}-select-modal`
  const attachBlockTitle = (() => {
    switch (attachBlockEntity) {
      case "contentUsage":
        return pluralize("content")
      case "occurrence":
        return "event"
      default:
        return pluralize(attachBlockEntity)
    }
  })()

  const classes = useStyles()
  const contentLabels = ContentUtils.useContentLabels()

  const options: AdminContentListLabelOption[] = [
    { value: null, title: "All Labels" },
    ...contentLabels.map((contentLabel) => ({
      value: contentLabel.id,
      title: contentLabel.label,
    })),
  ]

  function renderAttachBlockSearchBody() {
    switch (attachBlockEntity) {
      case "product":
        return (
          <AttachBlockProductSearchBody
            onClose={handleClose}
            contentSearchQuery={toolbarState.search}
            onProductSelect={getAttachBlockEntityId}
            inline={inline}
            productType={"course"}
          />
        )
      case "pathway":
        return (
          <AttachBlockProductSearchBody
            onClose={handleClose}
            contentSearchQuery={toolbarState.search}
            onProductSelect={getAttachBlockEntityId}
            inline={inline}
            productType={"pathway"}
          />
        )
      case "occurrence":
        return (
          <AttachBlockEventSearchBody
            inline={inline}
            onClose={handleClose}
            toolbarState={toolbarState}
            onEventSelect={getAttachBlockEntityId}
          />
        )
      case "contentUsage":
      case "survey":
        return (
          <AttachBlockContentUsageSearchBody
            inline={inline}
            onClose={handleClose}
            toolbarState={toolbarState}
            onContentUsageSelect={getAttachBlockEntityId}
            attachBlockEntity={attachBlockEntity}
          />
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (isOpen) {
      // Focus input after it is rendered
      setTimeout(() => inputRef.current?.focus(), 100)
    }
  }, [isOpen])

  return (
    <>
      <div ref={popoverAnchorRef} className={classes.anchor} />
      {isOpen && popoverAnchorRef.current && (
        <Popover
          open={isOpen}
          data-testid={ID}
          id={ID}
          anchorEl={anchorElem}
          keepMounted={Boolean(popoverAnchorRef.current)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "center", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          PaperProps={{
            classes: {
              root: classes.popoverPaper,
            },
            id: "media-library-popover",
          }}
        >
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              <DiscoText variant={"body-md-600"}>{`${capitalize(
                attachBlockTitle
              )}`}</DiscoText>
            </div>
            {/* Datetime Filter */}
            <div className={classes.filterButtonWrapper}>
              {attachBlockEntity === "occurrence" ? (
                <DiscoDropdown
                  menuButton={(buttonProps) => (
                    <DiscoButton
                      {...buttonProps}
                      color={"grey"}
                      variant={"outlined"}
                      rightIcon={<DropdownIcon />}
                      testid={`${ID}.filter-dropdown-button`}
                    >
                      {capitalize(toolbarState.eventFilter)}
                    </DiscoButton>
                  )}
                >
                  {EVENTS_FILTER_OPTIONS.map((item) => (
                    <DiscoDropdownItem
                      key={item.value}
                      selected={toolbarState.eventFilter === item.value}
                      title={item.title}
                      testid={`${ID}.filter-dropdown-button.${item.title}`}
                      onClick={() => handleDatetimeFilterChange(item.value)}
                    />
                  ))}
                </DiscoDropdown>
              ) : attachBlockEntity === "contentUsage" ? (
                <DiscoTableFilterDropdown
                  testid={`${ID}.label-select`}
                  options={options}
                  onOptionSelect={(contentLabelId) =>
                    setToolbarState({
                      ...toolbarState,
                      contentLabelId,
                    })
                  }
                  selectedOption={toolbarState.contentLabelId}
                />
              ) : null}
            </div>
            <DiscoIconButton
              color={theme.palette.text.primary}
              className={classes.navIcon}
              onClick={handleClose}
            >
              <DiscoIcon icon={"close"} />
            </DiscoIconButton>
          </div>

          <DiscoTableSearchInput
            inputRef={inputRef}
            testid={`${ID}.search-input`}
            onChange={handleSearchChange}
            placeholder={`Search ${attachBlockTitle}`}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <div className={classes.searchBody}>
            <DiscoText
              variant={"body-sm"}
              color={"groovy.neutral.400"}
              className={classes.attachText}
            >
              {"Attach"}
            </DiscoText>
            {renderAttachBlockSearchBody()}
          </div>
        </Popover>
      )}
    </>
  )
  function handleClose() {
    onClose()
    setToolbarState({
      search: "",
      contentLabelId: null,
      selectedProduct: null,
      selectedCollection: null,
      contentUsageCount: null,
      sort: {
        id: "none",
        order: { field: "created_at" },
        title: "Sort",
      },
      direction: "DESC",
      eventFilter: "upcoming",
    })
  }

  function handleSearchChange(value: string) {
    setToolbarState({
      ...toolbarState,
      search: value,
    })
  }

  function handleDatetimeFilterChange(value: EventsFilterOption) {
    setToolbarState({
      ...toolbarState,
      eventFilter: value,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  anchor: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  header: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  headerTitle: {
    marginLeft: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: theme.spacing(4),
  },
  filterButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: theme.spacing(4),
  },
  navIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: 0,
  },
  searchBody: {
    marginTop: theme.spacing(1),
  },
  attachText: {
    padding: theme.spacing(0, 0, 0.5, 1),
  },
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1.5, 1, 1, 1),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
    width: "300px",
    height: "fit-content",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 2),
      width: "348px",
    },
  },
}))

export default Relay.withSkeleton({
  component: observer(AttachBlockSelectModal),
  // ContentUtils.useContentLabels can cause this component to suspend
  skeleton: () => null,
})
