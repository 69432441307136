import { useLabels } from "@/core/context/LabelsContext"
import BoxIcon from "@/core/ui/iconsax/linear/box-1.svg"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { MissingAccessAttachBlock } from "@components/editor/plugins/attach-block/EditorAttachBlock"
import EditorPathwayAttachBlockChildProduct from "@components/editor/plugins/attach-block/types/pathway/EditorPathwayAttachBlockChildProduct"
import { EditorPathwayAttachBlockQuery } from "@components/editor/plugins/attach-block/types/pathway/__generated__/EditorPathwayAttachBlockQuery.graphql"
import { DiscoIcon, DiscoSection, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import pluralize from "pluralize"
import { graphql, useLazyLoadQuery } from "react-relay"

export const EditorPathwayAttachBlock: AttachBlockConfig = {
  title: "Pathway",
  icon: <BoxIcon height={18} width={18} />,
  keywords: ["pathway", "link"],
  Component({ entityId }) {
    const { node } = useLazyLoadQuery<EditorPathwayAttachBlockQuery>(
      graphql`
        query EditorPathwayAttachBlockQuery($id: ID!) {
          node(id: $id) {
            __typename
            ... on Product {
              id
              status
              childPathwayGroups {
                totalCount
                edges {
                  node {
                    id
                    title
                    products {
                      totalCount
                      edges {
                        node {
                          id
                          ...EditorPathwayAttachBlockChildProductFragment
                        }
                      }
                    }
                  }
                }
              }
              ...usePermissionsFragment
            }
          }
        }
      `,
      { id: entityId }
    )
    const pathwayProduct = Relay.narrowNodeType(node, "Product")
    const permissions = usePermissions(pathwayProduct)

    const labels = useLabels()
    const { pathway, experience } = labels

    const classes = useStyles()
    const theme = useTheme()

    const isInDraftMode = pathwayProduct?.status === "draft"

    const pathwayGroups = Relay.connectionToArray(pathwayProduct?.childPathwayGroups)

    // if the pathway is in draft and the user isn't an admin, show missing access block
    if (isInDraftMode && !permissions.has("pathways.manage")) {
      return <MissingAccessAttachBlock attachBlockEntity={"product"} />
    }

    if (!pathwayProduct) return null

    const totalGroups = pathwayProduct?.childPathwayGroups.totalCount
    const totalProducts = pathwayGroups.reduce(
      (acc, group) => acc + group.products.totalCount,
      0
    )

    return (
      <div className={classes.progressGrid}>
        <DiscoIcon icon={"rocket"} color={theme.palette.text.primary} />
        <DiscoText variant={"body-md-500"}>{`${totalGroups} ${
          pathway.singular
        } ${pluralize("Group", totalGroups)} • ${totalProducts} ${pluralize(
          experience.singular,
          totalProducts
        )}`}</DiscoText>
        <div className={classes.progressLine} />
        <div>
          {pathwayGroups.map((group) => (
            <DiscoSection
              key={group.id}
              className={classes.group}
              border
              marginBottom={1.5}
              padding={2}
            >
              <DiscoText className={classes.groupTitle}>{group.title}</DiscoText>
              {Relay.connectionToArray(group.products).map((product) => (
                <EditorPathwayAttachBlockChildProduct
                  key={product.id}
                  productKey={product}
                />
              ))}
            </DiscoSection>
          ))}
        </div>
        <DiscoIcon icon={"achievement"} color={theme.palette.text.primary} />
        <DiscoText variant={"body-md-600"}>{`${pathway.singular} Complete!`}</DiscoText>
      </div>
    )
  },
}

const useStyles = makeUseStyles((theme) => ({
  progressGrid: {
    display: "grid",
    position: "relative",
    rowGap: theme.spacing(1.5),
    columnGap: theme.spacing(1),
    gridTemplateColumns: "auto 1fr",
  },
  progressLine: {
    borderLeft: `1.5px dashed ${theme.palette.groovy.neutral[300]}`,
    justifySelf: "center",
  },
  group: {
    display: "grid",
    rowGap: theme.spacing(1.5),
  },
  groupTitle: {
    "&::after": {
      content: '""',
      position: "absolute",

      backgroundColor: theme.palette.groovy.neutral[500],
      borderRadius: "50%",
      height: 12,
      width: 12,

      left: 0,
      marginLeft: 6,
      marginTop: 6,
    },
  },
}))
