import { ProductType } from "@/admin/insights/member-reports/tabs/products/__generated__/AdminMembersReportProductsTableExportCSVButtonMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockProductModalListItem from "@components/editor/plugins/attach-block/types/product/AttachBlockProductModalListItem"
import { AttachBlockProductSearchBodyPaginationQuery } from "@components/editor/plugins/attach-block/types/product/__generated__/AttachBlockProductSearchBodyPaginationQuery.graphql"
import { AttachBlockProductSearchBodyQuery } from "@components/editor/plugins/attach-block/types/product/__generated__/AttachBlockProductSearchBodyQuery.graphql"
import { AttachBlockProductSearchBody_PaginationFragment$key } from "@components/editor/plugins/attach-block/types/product/__generated__/AttachBlockProductSearchBody_PaginationFragment.graphql"
import { DiscoButton, DiscoSection, DiscoText, DiscoTooltip } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

interface AttachBlockProductSearchBodyProps extends TestIDProps {
  onClose: VoidFunction
  contentSearchQuery?: string
  onProductSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
  productType: Extract<ProductType, "course" | "pathway">
}

const RESULTS_PER_PAGE = 10

function AttachBlockProductSearchBody({
  onClose,
  contentSearchQuery,
  onProductSelect,
  inline,
  productType,
}: AttachBlockProductSearchBodyProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<AttachBlockProductSearchBodyQuery>(
    graphql`
      query AttachBlockProductSearchBodyQuery(
        $type: String
        $id: ID!
        $first: Int
        $after: String
        $search: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...AttachBlockProductSearchBody_PaginationFragment
              @arguments(type: $type, first: $first, after: $after, search: $search)
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      search: contentSearchQuery || null,
      type: productType,
      first: RESULTS_PER_PAGE,
    },
    { fetchPolicy: "store-and-network" }
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    AttachBlockProductSearchBodyPaginationQuery,
    AttachBlockProductSearchBody_PaginationFragment$key
  >(
    graphql`
      fragment AttachBlockProductSearchBody_PaginationFragment on Organization
      @refetchable(queryName: "AttachBlockProductSearchBodyPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        type: { type: "String" }
        search: { type: "String" }
      ) {
        id
        products(first: $first, after: $after, type: $type, search: $search)
          @connection(key: "AttachBlockProductSearchBody_products") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...AttachBlockProductModalListItem_ProductFragment
            }
          }
        }
      }
    `,
    organization
  )
  const products = Relay.connectionToArray(data?.products)

  useEffect(() => {
    refetch(
      {
        first: RESULTS_PER_PAGE,
        search: contentSearchQuery,
      },
      { fetchPolicy: "store-and-network" }
    )
  }, [contentSearchQuery, refetch])

  function renderMoreCount() {
    if (!data?.products.totalCount) return null
    if (data.products.totalCount <= products.length) return null

    const totalMoreResults = data.products.totalCount - products.length

    const handleLoadMore = () => {
      if (hasNext && !isLoadingNext) {
        loadNext(RESULTS_PER_PAGE)
      }
    }
    if (isLoadingNext) return <AttachBlockProductSearchBodySkeleton />

    return (
      <DiscoTooltip
        content={`(${totalMoreResults} more ${pluralize("product", totalMoreResults)})`}
      >
        <div className={classes.loadMoreContainer}>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={handleLoadMore}
            disabled={isLoadingNext}
          >
            {"Load more"}
          </DiscoButton>
        </div>
      </DiscoTooltip>
    )
  }

  return (
    <DiscoSection className={classes.searchHits}>
      {products?.length ? (
        products.map((product) => (
          <AttachBlockProductModalListItem
            key={product.id}
            productKey={product}
            onClose={handleClose}
            onProductSelect={onProductSelect}
            inline={inline}
          />
        ))
      ) : (
        <DiscoText className={classes.empty} variant={"body-sm"} color={"text.secondary"}>
          {"No products"}
        </DiscoText>
      )}
      {renderMoreCount()}
    </DiscoSection>
  )
  function handleClose() {
    onClose()
  }
}

function AttachBlockProductSearchBodySkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection className={classes.searchHits}>
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={40}
      />
    </DiscoSection>
  )
}
const useStyles = makeUseStyles((theme) => ({
  empty: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  searchHits: {
    maxHeight: "250px",
    overflowY: "auto",
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  loadMoreContainer: {
    cursor: "pointer",
    textAlign: "center",
    padding: theme.spacing(1),
  },

  searchHit: {
    margin: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: AttachBlockProductSearchBody,
  skeleton: AttachBlockProductSearchBodySkeleton,
})
