import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import BookIcon from "@/core/ui/iconsax/bold/book-1.svg"
import CalendarIcon from "@/core/ui/iconsax/bold/calendar.svg"
import PageIcon from "@/core/ui/iconsax/bold/document-1.svg"
import FlashIcon from "@/core/ui/iconsax/bold/flash.svg"
import MessageIcon from "@/core/ui/iconsax/bold/message-text.svg"
import MessagesIcon from "@/core/ui/iconsax/bold/messages-2.svg"
import UsersIcon from "@/core/ui/iconsax/linear/custom-user-add.svg"
import ChannelsIcon from "@/core/ui/iconsax/linear/hashtag-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"

export enum GLOBAL_ADD_DROPDOWN_ITEM_KIND {
  direct_message = "direct_message",
  channel_message = "channel_message",
  post = "post",
  content = "content",
  event = "event",
  product = "product",
  automation = "automation",
  invite_member = "invite_member",
}

export interface GlobalAddDropdownItemProps {
  testid: string
  kind: GLOBAL_ADD_DROPDOWN_ITEM_KIND
  onClick?: React.MouseEventHandler
}
const GlobalAddDropdownItem: React.FC<GlobalAddDropdownItemProps> = ({
  kind,
  testid,
  onClick,
}) => {
  const experienceLabel = useLabel("admin_experience")
  const activeProduct = useActiveProduct()

  const values: Record<
    GLOBAL_ADD_DROPDOWN_ITEM_KIND,
    { icon: React.ReactNode; title: string }
  > = {
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.direct_message]: {
      icon: <MessagesIcon width={20} height={20} />,
      title: "Direct Message",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.channel_message]: {
      icon: <ChannelsIcon width={20} height={20} />,
      title: "Channel Message",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.post]: {
      icon: <MessageIcon width={20} height={20} />,
      title: "Feed Post",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.content]: {
      icon: <PageIcon width={20} height={20} />,
      title: "Content",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.event]: {
      icon: <CalendarIcon width={20} height={20} />,
      title: "Event",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.product]: {
      icon: <BookIcon width={20} height={20} />,
      title: experienceLabel.singular,
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.automation]: {
      icon: <FlashIcon width={20} height={20} />,
      title: "Automation",
    },
    [GLOBAL_ADD_DROPDOWN_ITEM_KIND.invite_member]: {
      icon: <UsersIcon width={20} height={20} />,
      title: `Invite to ${
        activeProduct?.type === "course"
          ? experienceLabel.singular
          : activeProduct?.type === "pathway"
          ? "Pathway"
          : "Community"
      }`,
    },
  }

  const classes = useStyles()
  const { icon, title } = values[kind] || {}
  const iconWrapper = <div className={classes.iconWrapper}>{icon}</div>

  return (
    <DiscoDropdownItem
      className={classes.item}
      classes={{ icon: classes.icon }}
      testid={`${testid}.${kind}`}
      onClick={onClick}
      icon={iconWrapper}
      title={title}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  icon: {
    margin: 0,
    "& svg": {
      width: 20,
      height: 20,
      color: theme.palette.groovy.neutral[700],
    },
  },
  iconWrapper: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    margin: 0,
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    height: theme.spacing(5),
    margin: `${theme.spacing(1)} !important`,
    display: "flex",
    alignItems: "center",
  },
}))

export default GlobalAddDropdownItem
