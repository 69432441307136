import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import classNames from "classnames"
import React from "react"
import ReactModal, { Props as ReactModalProps } from "react-modal"
import { MODAL_CLOSE_TIMEOUT } from "./util/modalConstants"

export type ModalProps = {
  isOpen: boolean
  modalContentLabel: string
  onClose?: (...args: any[]) => void
  onAfterOpen?: (...args: any[]) => void
  onAfterClose?: (...args: any[]) => void
  closeTimeout?: number
  shouldCloseOnOverlayClick?: boolean
  customClassName?: string
  overlayClassName?: string
  children?: React.ReactNode
  shouldCloseOnEsc?: boolean
  bodyOpenClassName?: string
  portalClassName?: string
  disableTransition?: boolean
  zIndex?: number
} & Pick<ReactModalProps, "parentSelector" | "contentRef">

ReactModal.defaultStyles = {}
ReactModal.setAppElement("#root")

function getParent() {
  return document.querySelector("#modal-root") as HTMLElement
}

function Modal({
  isOpen,
  customClassName,
  children,
  closeTimeout = MODAL_CLOSE_TIMEOUT,
  modalContentLabel,
  onAfterClose,
  onAfterOpen,
  overlayClassName,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  bodyOpenClassName,
  onClose,
  portalClassName,
  parentSelector,
  disableTransition = false,
  zIndex,
  contentRef,
}: ModalProps) {
  const classes = useStyles({ disableTransition, zIndex })
  const containerClassName = classNames(classes.container, customClassName)
  const bodyClassName = classNames("ReactModal__Body--open", bodyOpenClassName)

  return (
    // This wrapper div and click handler is added to stop any click events from bubbling outside of the modal
    // eslint-disable-next-line
    <div style={{ position: "absolute" }} onClick={(event) => event.stopPropagation()}>
      <ReactModal
        contentRef={contentRef}
        parentSelector={parentSelector || getParent}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        contentLabel={modalContentLabel}
        className={containerClassName}
        portalClassName={classNames(classes.portal, portalClassName)}
        onRequestClose={handleRequestClose}
        overlayClassName={overlayClassName}
        closeTimeoutMS={closeTimeout}
        bodyOpenClassName={bodyClassName}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
      >
        {children}
      </ReactModal>
    </div>
  )

  function handleRequestClose() {
    if (onClose && (shouldCloseOnOverlayClick || shouldCloseOnEsc)) {
      onClose()
    }
  }
}

type StyleProps = Pick<ModalProps, "disableTransition"> & {
  zIndex?: number
}

const useStyles = makeUseStyles((theme) => ({
  portal: (props: StyleProps) => ({
    position: "relative",
    zIndex: props.zIndex ?? theme.zIndex.modal,

    "& .ReactModal__Overlay": {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,

      ...styleIf(!props.disableTransition, {
        transition: "opacity 0.2s ease-in-out",
      }),
    },

    "& .ReactModal__Overlay--after-open": {
      opacity: 1,
    },

    "& .ReactModal__Overlay--before-close": {
      opacity: 0,
    },
  }),
  container: {
    position: "relative",
    top: "50%",
    left: "50%",

    maxWidth: "75vw",
    maxHeight: "85vh",

    overflow: "auto",

    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.boxShadow,

    transform: "translate(-50%, -50%)",

    "&:focus": {
      outline: "none",
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "95vw",
    },
  },
}))

export default Modal
