import { GlobalID } from "@/relay/RelayTypes"
import EditorAttachBlock from "@components/editor/plugins/attach-block/EditorAttachBlock"
import EditorContentUsageAttachBlock from "@components/editor/plugins/attach-block/types/content-usage/EditorContentUsageAttachBlock"
import EditorEventAttachBlock from "@components/editor/plugins/attach-block/types/event/EditorEventAttachBlock"
import { EditorPathwayAttachBlock } from "@components/editor/plugins/attach-block/types/pathway/EditorPathwayAttachBlock"
import EditorProductAttachBlock from "@components/editor/plugins/attach-block/types/product/EditorProductAttachBlock"
import EditorSurveyAttachBlock from "@components/editor/plugins/attach-block/types/survey/EditorSurveyAttachBlock"
import { DiscoIconKinds } from "@disco-ui"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"
import React from "react"

export type AttachBlockEntity =
  | "occurrence"
  | "contentUsage"
  | "product"
  | "survey"
  | "pathway"

export interface AttachBlockPayload {
  key?: NodeKey
  attachBlockEntity: AttachBlockEntity
  entityId: GlobalID
}

export type SerializedAttachBlockNode = Spread<
  {
    attachBlockEntity: AttachBlockEntity
    entityId: GlobalID
  },
  SerializedDecoratorBlockNode
>

export class AttachBlockNode extends DecoratorBlockNode {
  __attachBlockEntity: AttachBlockEntity
  __entityId: GlobalID

  static getType(): string {
    return "attachBlock"
  }

  static clone(node: AttachBlockNode): AttachBlockNode {
    return new AttachBlockNode(
      {
        attachBlockEntity: node.__attachBlockEntity,
        entityId: node.__entityId,
      },
      node.__key,
      node.__format
    )
  }

  static importJSON(serializedNode: SerializedAttachBlockNode): AttachBlockNode {
    const { attachBlockEntity, entityId } = serializedNode
    const node = $createAttachBlockNode({ attachBlockEntity, entityId })
    return node
  }

  constructor(
    attachBlock: AttachBlockPayload,
    key?: NodeKey,
    format?: ElementFormatType
  ) {
    super(format, key)
    this.__attachBlockEntity = attachBlock.attachBlockEntity
    this.__entityId = attachBlock.entityId
  }

  exportJSON(): SerializedAttachBlockNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "attachBlock",
      attachBlockEntity: this.getAttachBlockEntity(),
      entityId: this.getEntityId(),
    }
  }

  // View
  updateDOM(): false {
    return false
  }

  getAttachBlockEntity(): AttachBlockEntity {
    return this.__attachBlockEntity
  }

  getEntityId(): GlobalID {
    return this.__entityId
  }

  decorate(): JSX.Element {
    return (
      <EditorAttachBlock
        attachBlockEntity={this.getAttachBlockEntity()}
        entityId={this.getEntityId()}
      />
    )
  }
}

export function $createAttachBlockNode({
  key,
  attachBlockEntity,
  entityId,
}: AttachBlockPayload): AttachBlockNode {
  return new AttachBlockNode({ attachBlockEntity, entityId }, key)
}

export function $isAttachBlockNode(
  node: LexicalNode | null | undefined
): node is AttachBlockNode {
  return node instanceof AttachBlockNode
}

export interface AttachBlockConfig {
  title: string
  icon: DiscoIconKinds | React.ReactElement
  keywords: string[]
  Component: React.FC<{
    entityId: GlobalID
    onClose?: (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
    ) => void
    attachBlockEntity?: AttachBlockEntity
  }>
}

export const ATTACH_BLOCK_CONFIG: Record<AttachBlockEntity, AttachBlockConfig> = {
  occurrence: EditorEventAttachBlock,
  product: EditorProductAttachBlock,
  contentUsage: EditorContentUsageAttachBlock,
  survey: EditorSurveyAttachBlock,
  pathway: EditorPathwayAttachBlock,
}
